import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgcCookieConsentService, NgcNoCookieLawEvent, NgcInitializeEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { RgEventsService } from './services/rg-events/rg-events.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'news-letter';

  // keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private ccService: NgcCookieConsentService,
    private translateService: TranslateService,
    private rgEventService: RgEventsService,
  ) {
    this.internationalization();
  }

  ngOnInit() {
    if (environment.production) {
      if (window) {
        window.console.log = () => {
        };
        window.console.info = () => {
        };
        window.console.warn = () => {
        };
        window.console.debug = () => {
        };
        window.console.time = () => {
        };
        window.console.timeEnd = () => {
        };
        window.console.trace = () => {
        };
        window.console.error = (error) => {
          // call endpoint sending error object
        };
      }
    }

    this.loadCookieConsentSettings();
    this.translateCookieConsent();
    this.rgEventService.on('CHANGED_LANGUAGE', () => this.translateCookieConsent());

    if (!localStorage.CONSENT_ACCEPTED || localStorage.CONSENT_ACCEPTED !== 'false') {
      this.ccService.clearStatus();
    }
  }

  removeDivConsent() {
    if (document.querySelector('body > div.cc-window.cc-floating.cc-type-opt-out.cc-theme-edgeless.cc-bottom.cc-invisible')) {
      document.querySelector('body > div.cc-window.cc-floating.cc-type-opt-out.cc-theme-edgeless.cc-bottom.cc-invisible').remove();
    }
    if (document.querySelector('body > div.cc-window.cc-banner.cc-type-opt-out.cc-theme-edgeless.cc-bottom')) {
      document.querySelector('body > div.cc-window.cc-banner.cc-type-opt-out.cc-theme-edgeless.cc-bottom').remove();
    }
    if (document.querySelector('body > div.cc-revoke.cc-bottom')) {
      document.querySelector('body > div.cc-revoke.cc-bottom').remove();
    }
    if (document.querySelector('.cc-revoke .cc-bottom')) {
      document.querySelector('.cc-revoke .cc-bottom').remove();
    }
  }

  loadCookieConsentSettings() {
    // https://www.npmjs.com/package/ngx-cookieconsent
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      (event) => {
        // you can use this.ccService.getConfig() to do stuff..
        console.log('popupOpen', event);
        if (localStorage.CONSENT_ACCEPTED) {
          this.removeDivConsent();
        }
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      (event) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('popupClose', event);
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('initialize', event);
      });

      // tslint:disable-next-line: align
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('statusChange', event);
        if (event && event.status === 'allow') {
          localStorage.CONSENT_ACCEPTED = true;
          this.removeDivConsent();
        }
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      (event) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('revokeChoice', event);
      });

      // tslint:disable-next-line: align
    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('noCookieLaw', event);
      });
  }


  translateCookieConsent() {
    // Support for translated cookies messages
    this.translateService.addLangs(['pt-BR', 'pt-PT', 'en', 'es']);
    this.translateService.setDefaultLang(localStorage.LANG || 'en');

    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(localStorage.LANG || 'en');

    this.translateService//
      // tslint:disable-next-line: max-line-length
      .get([
        'cookie.header',
        'cookie.message',
        'cookie.dismiss',
        'cookie.allow',
        'cookie.deny',
        'cookie.link',
        'cookie.policy',
        'cookie.cookiePolicyLink',
        'cookie.cookiePolicyHref',
        'cookie.privacyPolicyLink',
        'cookie.privacyPolicyHref',
        'cookie.tosLink',
        'cookie.tosHref'
      ])
      .subscribe(data => {

        this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = data['cookie.header'];
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.ccService.getConfig().content.allow = data['cookie.allow'];
        this.ccService.getConfig().content.deny = data['cookie.deny'];
        this.ccService.getConfig().content.link = data['cookie.link'];
        this.ccService.getConfig().content.policy = data['cookie.policy'];
        this.ccService.getConfig().content.cookiePolicyLink = data['cookie.cookiePolicyLink'];
        this.ccService.getConfig().content.cookiePolicyHref = data['cookie.cookiePolicyHref'];
        this.ccService.getConfig().content.privacyPolicyLink = data['cookie.privacyPolicyLink'];
        this.ccService.getConfig().content.privacyPolicyHref = data['cookie.privacyPolicyHref'];
        this.ccService.getConfig().content.tosLink = data['cookie.tosLink'];
        this.ccService.getConfig().content.tosHref = data['cookie.tosHref'];

        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  internationalization() {
    if (!localStorage.LANG) {
      localStorage.LANG = 'pt-PT';

      const possibleLanguages = ['en', 'es', 'pt-BR', 'pt-PT'];
      let lang = this.translate.getBrowserCultureLang();
      if (lang) {
        if (!possibleLanguages.includes(lang)) {
          if (lang === 'en-US') { lang = 'en'; } else { lang = 'pt-PT'; }
        }
        localStorage.LANG = lang;
      }

      this.translate.setDefaultLang(localStorage.LANG || 'en');
      this.translate.use(lang).subscribe(() => {
      });
    } else {
      this.translate.setDefaultLang(localStorage.LANG || 'en');
    }
  }
}


