import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Util } from 'src/app/Util';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Paths } from 'src/app/Paths';
import { HttpHeaders } from '@angular/common/http';

export interface IAnalytics {
  datetime?: string;
  time?: string;
  type?: string;
  filter?: string;
  app?: string;
  metadata?: string;
  user?: string;
  auth?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private http: HttpClient
  ) { }


  public register(analytics: IAnalytics): void {
    analytics.datetime = moment().toISOString();
    analytics.app = environment.analyticsApp;
    // analytics.auth = `S8wyF"'D-GxEz\`UD5*PBw%>sS}Jhm`;

    if (analytics.metadata) {
      analytics.metadata = JSON.stringify(analytics.metadata);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        // tslint:disable-next-line: object-literal-key-quotes
        'safe': 'true'
      })
    };

    this.http.post(Paths.ANALYTICS, Util.getSafeContent(analytics), httpOptions)
    .toPromise()
    .then((data) => Util.safeResponse(data));
  }
}
