import { Component, OnInit } from '@angular/core';
import smoothscroll from 'smoothscroll-polyfill';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { Util } from 'src/app/Util';
import { RgEventsService } from 'src/app/services/rg-events/rg-events.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import * as publicIp from 'public-ip';
import * as detectBrowser from 'detect-browser';
import { NewsletterService } from 'src/app/services/newsletter/newsletter.service';
import { TranslateService } from '@ngx-translate/core';
import { timer, Subscription } from 'rxjs';
import { Pipe, PipeTransform } from "@angular/core";
import * as moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  appSelected = 'Others';
  percentagem: any = 0;
  faturamentoMensal: any = 0;
  calcErrors: any = [];

  resultadoMediaMeses = null;
  resultadoMediaAnos = null;

  before = 'home';

  urlKiosk: any;

  apps = {
    en: [
      { value: 'Uber Eats', description: 'Uber EATS'},
      { value: 'Glovo', description: 'Glovo'}
    ],
    br: [
      { value: 'Uber Eats', description: 'Uber EATS'},
      { value: 'iFood', description: 'iFood'},
      { value: 'Rappi', description: 'Rappi'},
      { value: 'Pedidos Já', description: 'Pedidos Já'}
    ],
    pt: [
      { value: 'Uber Eats', description: 'Uber EATS'},
      { value: 'Glovo', description: 'Glovo'}
    ],
    es: [
      { value: 'Uber Eats', description: 'Uber EATS'},
      { value: 'Glovo', description: 'Glovo'}
    ],
  };

  languagesCurrency = {
    en: { side: 'right', currency: '£'},
    br: { side: 'left', currency: 'R$'},
    pt: { side: 'right', currency: '€'},
    es: { side: 'right', currency: '€'}
  };
  languageCurrencySelected = { side: 'left', currency: 'R$'};

  newsletterSentSuccessfully = false;
  emailNewsletter = '';
  newsletterErrorMsg = [];
  newsletterPolicy = false;
  newsletterLoaderActive = false;

  cookiePolicyHref = '';
  privacyPolicyHref = '';
  tosHref = '';

  countDown: Subscription;
  counter = 88400;
  tick = 1000;

  myStyle: object = {};
  myParams: object = {};
  width: number = 100;
  height: number = 100;

  constructor(
    private sanitizer: DomSanitizer,
    private rgEventService: RgEventsService,
    private analyticsService: AnalyticsService,
    private newsletterService: NewsletterService,
    private translateService: TranslateService,
    public dialog: MatDialog,
  ) {
    smoothscroll.polyfill();
    // tslint:disable-next-line: max-line-length
    this.urlKiosk = this.sanitizer.bypassSecurityTrustResourceUrl(`https://app-kiosk-delivery.firebaseapp.com/on-board?v=${new Date().toISOString()}`);
    // this.urlKiosk = this.sanitizer.bypassSecurityTrustResourceUrl(`http://localhost:8100?v=${new Date().toISOString()}`);
  }

  ngOnInit() {
    this.calcTheTimeForCountdown();

    this.myStyle = {
      'position': 'absolute',
      'width': '100%',
      'height': '100%',
      'z-index': 2,
      'top': 0,
      'left': 0,
      'right': 0,
      'bottom': 0,
      'overflow': 'hidden',
    };

    this.myParams = {
      particles: {
        number: {
          value: 80,
          density: {
            enable: true,
            value_area: 800
          }
        },
        color: {
          value: '#14212d'
        },
        shape: {
          type: 'circle',
          stroke: {
            width: 0,
            color: '#31afb4'
          },
          polygon: {
            nb_sides: 5
          },
          image: {
            src: 'img/github.svg',
            width: 100,
            height: 100
          }
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false
          }
        },
        size: {
          value: 3,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false
          }
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: '#31afb4',
          opacity: 0.4,
          width: 1
        },
        move: {
          enable: true,
          speed: 6,
          direction: 'none',
          random: false,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200
          }
        }
      },
      interactivity: {
        detect_on: 'canvas',
        events: {
          onhover: {
            enable: true,
            mode: 'repulse'
          },
          onclick: {
            enable: false,
            mode: 'push'
          },
          resize: true
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 1
            }
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3
          },
          repulse: {
            distance: 200,
            duration: 0.4
          },
          push: {
            particles_nb: 4
          },
          remove: {
            particles_nb: 2
          }
        }
      },
      retina_detect: true
    };


    this.countDown = timer(0, this.tick).subscribe(() => --this.counter);
    this.translateCookieConsent();
    this.changedLanguage();
    this.rgEventService.on('CHANGED_LANGUAGE', () => {
      this.translateCookieConsent();
      this.changedLanguage();
    });
    this.setInitAnalytics();
  }

  calcTheTimeForCountdown() {
    // Day of product launch! 07/12/2020
    const a = moment('07/12/2020', 'DD/MM/YYYY').set({hour:0,minute:0,second:0,millisecond:0});
    const b = moment();
    this.counter = a.diff(b, 'seconds');
  }

  async setInitAnalytics() {
    localStorage.BEFORE = 'home';
    // const ipv6 = await publicIp.v6() || 'unavailable';
    const ipv4 = await publicIp.v4() || 'unavailable';
    const browser: any = detectBrowser.detect();

    const analyticsOptions: any = {
      type: 'home', // home represents the first access
      filter: 'first-access',
      metadata: {
        lang: localStorage.LANG,
        ipv4,
        browser: {
          name: browser.name || 'unavailable',
          version: browser.version || 'unavailable',
          os: browser.os || 'unavailable',
        }
      }
    };

    try {
      const analytics = await this.analyticsService.register(analyticsOptions);
    } catch (error) {
    }
  }

  async setOptionsAnalytics(target, filter, metadata: any = {}) {
    // const ipv6 = await publicIp.v6() || 'unavailable';
    const ipv4 = await publicIp.v4() || 'unavailable';
    const browser: any = detectBrowser.detect();

    const analyticsOptions: any = {
      filter,
      type: target, // navigated to other link
      metadata: {
        lang: localStorage.LANG,
        beforeLink: localStorage.BEFORE || this.before,
        ipv4,
        browser: {
          name: browser.name || 'unavailable',
          version: browser.version || 'unavailable',
          os: browser.os || 'unavailable',
        },
      }
    };
    analyticsOptions.metadata.others = metadata;

    try {
      const analytics = await this.analyticsService.register(analyticsOptions);
      this.before = target;
      localStorage.BEFORE = target;
    } catch (error) {
    }
  }

  getLocalStorageValue() {
    return localStorage.LANG || 'pt-BR';
  }

  goTo(section) {
    document.querySelector(`#${section}`).scrollIntoView({ behavior: 'smooth' });
  }

  calc() {
    if (this.calcIsValid()) {
      const comissionamento     = this.comissoes(this.appSelected);
      this.resultadoMediaMeses  = this.faturamentoMensal * this.percentagem / 100 * comissionamento / 100;
      this.resultadoMediaAnos   = this.resultadoMediaMeses * 12;

      // tslint:disable-next-line: radix
      this.resultadoMediaMeses = Number(parseInt(this.resultadoMediaMeses)).toLocaleString('pt') + ',00';
      // tslint:disable-next-line: radix
      this.resultadoMediaAnos = Number(parseInt(this.resultadoMediaAnos)).toLocaleString('pt') + ',00';

      this.setOptionsAnalytics('price-comparison', '', {
        priceComparison: {
          company: this.appSelected,
          percentageMonthlyRevenue: this.percentagem,
          monthlyInvoicing: this.faturamentoMensal,
          resultEveryMonth: this.resultadoMediaMeses,
          resultEveryYear: this.resultadoMediaAnos
        }
      });
    }
  }

  comissoes(empresa) {
    switch (empresa) {
      case 'Uber Eats':
        return 30;
      case 'Glovo':
        return 30;
      case 'iFood':
        return 15;
      case 'Rappi':
        return 25;
      case 'Pedidos Já':
        return 15;
      case 'Others':
        return 15.5;
      default:
        return 0;
    }
  }

  calcIsValid() {
    const hasError = false;
    this.calcErrors = [];
    if (!this.appSelected || !this.percentagem || !this.faturamentoMensal) {
      this.calcErrors.push('All fields above are mandatory. Please fill them');
    }

    if (this.percentagem > 100) {
      this.calcErrors.push('Percentage value must be less than 100');
    }

    if (this.percentagem <= 0) {
      this.calcErrors.push('Percentage value must be more than 1');
    }

    if (hasError) {
      return false;
    }

    return true;
  }

  cleanResults() {
    this.calcErrors = [];
    this.resultadoMediaMeses = null;
    this.resultadoMediaAnos = null;
  }

  changedLanguage() {
    this.cleanResults();
    switch (localStorage.LANG) {
      case 'en':
        this.languageCurrencySelected = this.languagesCurrency.en;
        return;
      case 'pt-PT':
        this.languageCurrencySelected = this.languagesCurrency.pt;
        return;
      case 'pt-BR':
        this.languageCurrencySelected = this.languagesCurrency.br;
        return;
      case 'es':
        this.languageCurrencySelected = this.languagesCurrency.es;
        return;
      default:
        this.languageCurrencySelected = this.languagesCurrency.pt;
        break;
    }
  }

  translateCookieConsent() {
    // Support for translated cookies messages
    this.translateService.addLangs(['pt-BR', 'pt-PT', 'en', 'es']);
    this.translateService.setDefaultLang(localStorage.LANG || 'en');

    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(localStorage.LANG || 'en');

    this.translateService//
      // tslint:disable-next-line: max-line-length
      .get([
        'cookie.cookiePolicyHref',
        'cookie.privacyPolicyHref',
        'cookie.tosHref'
      ])
      .subscribe(data => {
        this.cookiePolicyHref = data['cookie.cookiePolicyHref'];
        this.privacyPolicyHref = data['cookie.privacyPolicyHref'];
        this.tosHref = data['cookie.tosHref'];
      });
  }

  cleanNewsletter() {
    this.emailNewsletter = '';
  }

  newsletterFormIsValid() {
    this.newsletterErrorMsg = [];
    if ( !this.emailNewsletter ) {
      this.newsletterErrorMsg.push('E-mail is a required field.');
    } else if (!Util.validateEmail(this.emailNewsletter)) {
      this.newsletterErrorMsg.push('Invalid email.');
    } else if (!this.newsletterPolicy) {
      this.newsletterErrorMsg.push('You need accept the terms.');
    }

    if (this.newsletterErrorMsg.length > 0) {
      return false;
    }

    return true;
  }

  async sendNewsletter() {
    if (this.newsletterFormIsValid()) {
      this.newsletterLoaderActive = true;
      try {
        const result = await this.newsletterService.register(this.emailNewsletter);
        this.cleanNewsletter();
        if (result) {
          this.newsletterLoaderActive = false;
          this.newsletterSentSuccessfully = true;

          this.newsletterPolicy = false;          

          setTimeout(() => {
            this.newsletterSentSuccessfully = false;
          }, 10000);
        }
      } catch (error) {
        this.newsletterSentSuccessfully = false;
      } finally {
        this.newsletterLoaderActive = false;
      }
    }
  }

  ngOnDestroy() {
    this.countDown = null;
  }
}


@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    let seconds = value;
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);

    hours = hours-(days*24);
    minutes = minutes-(days*24*60)-(hours*60);
    seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

    return (
      ("00" + days).slice(-2) +
      ":" +
      ("00" + hours).slice(-2) +
      ":" +
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + seconds).slice(-2)
    );
  }
}

@Pipe({
  name: "formatTimeDays"
})
export class FormatTimeDaysPipe implements PipeTransform {
  transform(value: number): string {
    let seconds = value;
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);

    hours = hours-(days*24);
    minutes = minutes-(days*24*60)-(hours*60);
    seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

    return (
      ("00" + days).slice(-2)
    );
  }
}

@Pipe({
  name: "formatTimeHours"
})
export class FormatTimeHoursPipe implements PipeTransform {
  transform(value: number): string {
    let seconds = value;
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);

    hours = hours-(days*24);
    minutes = minutes-(days*24*60)-(hours*60);
    seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

    return (
      ("00" + hours).slice(-2)
    );
  }
}

@Pipe({
  name: "formatTimeMinutes"
})
export class FormatTimeMinutesPipe implements PipeTransform {
  transform(value: number): string {
    let seconds = value;
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);

    hours = hours-(days*24);
    minutes = minutes-(days*24*60)-(hours*60);
    seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

    return (
      ("00" + minutes).slice(-2)
    );
  }
}

@Pipe({
  name: "formatTimeSeconds"
})
export class FormatTimeSecondsPipe implements PipeTransform {
  transform(value: number): string {
    let seconds = value;
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);

    hours = hours-(days*24);
    minutes = minutes-(days*24*60)-(hours*60);
    seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

    return (
      ("00" + seconds).slice(-2)
    );
  }
}
