import { Injectable } from '@angular/core';
import { Util } from 'src/app/Util';
import { HttpClient } from '@angular/common/http';
import { Paths } from 'src/app/Paths';

@Injectable({
  providedIn: 'root'
})
export class InterestsService {

  constructor(
    private http: HttpClient
  ) { }

  public send(interests: any) {
    return this.http.post(`${Paths.INTERESTS}`,  interests)
    .toPromise()
    .then((data) => Util.safeResponse(data));
  }

}
