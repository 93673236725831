import { Component, OnInit } from '@angular/core';
import smoothscroll from 'smoothscroll-polyfill';
import { TranslateService } from '@ngx-translate/core';
import { RgEventsService } from 'src/app/services/rg-events/rg-events.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import * as publicIp from 'public-ip';
import * as detectBrowser from 'detect-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  languageSelected = { description: '', code: '', flagCode: '', active: true };
  allLanguages = [
    { description: 'English', code: 'en', flagCode: 'gb', active: true },
    { description: 'Brazilian Portuguese', code: 'pt-BR', flagCode: 'br', active: true },
    { description: 'Portugal Portuguese', code: 'pt-PT', flagCode: 'pt', active: true },
    { description: 'French', code: 'fr', flagCode: 'fr', active: false },
    { description: 'Spanish', code: 'es', flagCode: 'es', active: true },
  ];

  before = 'home';

  constructor(
    private translate: TranslateService,
    private rgEventService: RgEventsService,
    private analyticsService: AnalyticsService,
  ) {
    smoothscroll.polyfill();
  }

  ngOnInit() {
    this.getSelectedLanguageFlag();
  }

  goTo(section) {
    document.querySelector(`#${section}`).scrollIntoView({ behavior: 'smooth' });
  }

  loadFlag(lang) {
    return `flag-icon-${lang}`;
  }

  selectLanguage(lang) {
    localStorage.LANG = lang.code;

    this.translate.use(lang.code).subscribe(async () => {
      this.setLanguageSelected(lang);
      this.rgEventService.broadcast('CHANGED_LANGUAGE');
    });
  }

  getSelectedLanguageFlag() {
    const language = localStorage.LANG;

    const languageFound = this.allLanguages
    .find((item) => item.code === language);
    this.languageSelected = languageFound || { description: 'English', code: 'en', flagCode: 'gb', active: true };
  }

  setLanguageSelected(lang) {
    this.allLanguages.forEach((selected) => {
      if (selected.code === lang.code) {
        this.languageSelected = selected;
      }
    });
  }

  async setOptionsAnalytics(target, filter, metadata: any = {}) {
    if (localStorage.BEFORE) {
      this.before = localStorage.BEFORE;
    }

    // const ipv6 = await publicIp.v6() || 'unavailable';
    const ipv4 = await publicIp.v4() || 'unavailable';
    const browser: any = detectBrowser.detect();

    const analyticsOptions: any = {
      filter,
      type: target,
      metadata: {
        lang: localStorage.LANG,
        beforeLink: localStorage.BEFORE || this.before,
        ipv4,
        browser: {
          name: browser.name || 'unavailable',
          version: browser.version || 'unavailable',
          os: browser.os || 'unavailable',
        },
      }
    };
    analyticsOptions.metadata.others = metadata;

    try {
      const analytics = await this.analyticsService.register(analyticsOptions);
      this.before = target;
      localStorage.BEFORE = target;
    } catch (error) {
    }
  }
}
