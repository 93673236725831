import { Component, OnInit } from '@angular/core';
import { Util } from 'src/app/Util';
import { NewsletterService } from 'src/app/services/newsletter/newsletter.service';
import { InterestsService } from 'src/app/services/interests/interests.service';
import { TranslateService } from '@ngx-translate/core';
import { RgEventsService } from 'src/app/services/rg-events/rg-events.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import * as publicIp from 'public-ip';
import * as detectBrowser from 'detect-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  contactErrorMsg = [];
  contactSentSuccessfully = false;
  contact: any = {
    name: '',
    phone: '',
    email: '',
    subject: '',
    description: ''
  };
  contactPolicy = false;

  before = 'home';

  newsletterSentSuccessfully = false;
  emailNewsletter = '';
  newsletterErrorMsg = [];
  newsletterPolicy = false;

  cookiePolicyHref = '';
  privacyPolicyHref = '';
  tosHref = '';

  constructor(
    private newsletterService: NewsletterService,
    private interestsService: InterestsService,
    private translateService: TranslateService,
    private rgEventService: RgEventsService,
    private analyticsService: AnalyticsService,
  ) {

  }

  ngOnInit() {
    this.translateCookieConsent();
    this.rgEventService.on('CHANGED_LANGUAGE', () => this.translateCookieConsent());
  }

  async sendContact() {
    if (this.contactFormIsValid()) {
      try {
        this.contact.lang = localStorage.LANG || null;
        const result = await this.interestsService.send(this.contact);
        if (result) {
          this.cleanContact();
          this.contactSentSuccessfully = true;

          setTimeout(() => {
            this.contactSentSuccessfully = false;
          }, 10000);
        }

      } catch (error) {
      }
    }
  }

  contactFormIsValid() {
    this.contactErrorMsg = [];
    if (
      !this.contact.name
      || !this.contact.email
      || !this.contact.subject
      || !this.contact.description
    ) {
      this.contactErrorMsg.push('There are mandatory fields to fill.');
    } else if (!Util.validateEmail(this.contact.email)) {
      this.contactErrorMsg.push('Invalid email.');
    } else if (!this.contactPolicy) {
      this.contactErrorMsg.push('You need accept the terms.');
    }

    if (this.contactErrorMsg.length > 0) {
      return false;
    }

    return true;
  }

  cleanContact() {
    this.contact = {
      name: '',
      phone: '',
      email: '',
      subject: '',
      description: '',
      lang: null
    };
  }

  async sendNewsletter() {
    if (this.newsletterFormIsValid()) {
      try {
        const result = await this.newsletterService.register(this.emailNewsletter);
        this.cleanNewsletter();
        if (result) {
          this.newsletterSentSuccessfully = true;

          setTimeout(() => {
            this.newsletterSentSuccessfully = false;
          }, 10000);
        }
      } catch (error) {
      }
    }
  }

  newsletterFormIsValid() {
    this.newsletterErrorMsg = [];
    if ( !this.emailNewsletter ) {
      this.newsletterErrorMsg.push('E-mail is a required field.');
    } else if (!Util.validateEmail(this.emailNewsletter)) {
      this.newsletterErrorMsg.push('Invalid email.');
    } else if (!this.newsletterPolicy) {
      this.newsletterErrorMsg.push('You need accept the terms.');
    }

    if (this.newsletterErrorMsg.length > 0) {
      return false;
    }

    return true;
  }

  cleanNewsletter() {
    this.emailNewsletter = '';
  }

  goTo(section) {
    if (section) {
      document.querySelector(`#${section}`).scrollIntoView({ behavior: 'smooth' });
    }
  }

  goToLink(url) {
    if (url === 'cookiePolicyHref') {
      window.open(this.cookiePolicyHref, '_blank');
    }

    if (url === 'privacyPolicyHref') {
      window.open(this.privacyPolicyHref, '_blank');
    }

    if (url === 'tosHref') {
      window.open(this.tosHref, '_blank');
    }
  }

  translateCookieConsent() {
    // Support for translated cookies messages
    this.translateService.addLangs(['pt-BR', 'pt-PT', 'en', 'es']);
    this.translateService.setDefaultLang(localStorage.LANG || 'en');

    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(localStorage.LANG || 'en');

    this.translateService//
      // tslint:disable-next-line: max-line-length
      .get([
        'cookie.cookiePolicyHref',
        'cookie.privacyPolicyHref',
        'cookie.tosHref'
      ])
      .subscribe(data => {
        this.cookiePolicyHref = data['cookie.cookiePolicyHref'];
        this.privacyPolicyHref = data['cookie.privacyPolicyHref'];
        this.tosHref = data['cookie.tosHref'];
      });
  }

  async setOptionsAnalytics(target, filter, metadata: any = {}) {
    if (localStorage.BEFORE) {
      this.before = localStorage.BEFORE;
    }

    // const ipv6 = await publicIp.v6() || 'unavailable';
    const ipv4 = await publicIp.v4() || 'unavailable';
    const browser: any = detectBrowser.detect();

    const analyticsOptions: any = {
      filter,
      type: target, // navigated to other link
      metadata: {
        lang: localStorage.LANG,
        beforeLink: localStorage.BEFORE || this.before,
        ipv4,
        browser: {
          name: browser.name || 'unavailable',
          version: browser.version || 'unavailable',
          os: browser.os || 'unavailable',
        },
      }
    };
    analyticsOptions.metadata.others = metadata;

    try {
      const analytics = await this.analyticsService.register(analyticsOptions);
      this.before = target;
      localStorage.BEFORE = target;
    } catch (error) {
    }
  }
}
