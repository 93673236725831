import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { FormatTimeDaysPipe, FormatTimeHoursPipe, FormatTimeMinutesPipe, FormatTimePipe, FormatTimeSecondsPipe, HomeComponent } from './components/site/home/home.component';
import { AboutComponent } from './components/site/about/about.component';
import { BlogHomeComponent } from './components/site/blog-home/blog-home.component';
import { BlogSingleComponent } from './components/site/blog-single/blog-single.component';
import { ContactComponent } from './components/site/contact/contact.component';
import { GalleryComponent } from './components/site/gallery/gallery.component';
import { ElementsComponent } from './components/site/elements/elements.component';
import { MenuComponent } from './components/site/menu/menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularMaterialModule } from './angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCurrencyModule } from 'ngx-currency';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { BrMaskerModule } from 'br-mask';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { CookiePolicyComponent } from './components/site/cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './components/site/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './components/site/terms-of-service/terms-of-service.component';
import { MatProgressSpinnerModule } from '@angular/material';
import { ParticlesModule } from 'angular-particle';

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  allowZero: false,
  decimal: ',',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: '.',
  nullable: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: ''// it is recommended to set your domain, for cookies to work properly
  },
  position: 'bottom',
  palette: {
    popup: {
      text: '#FFFFFF',
      background: '#316B84'
    },
    button: {
      background: '#82CDD1'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  layout: 'cookie-consent-content',
  layouts: {
    'cookie-consent-content': '{{messagelink}}{{compliance}}'
  },
  elements:{
    messagelink: `
    <span class="cookie-consent-content" id="cookieconsent:desc" class="cc-message">{{message}}
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" target="_blank" href="{{cookiePolicyHref}}">{{cookiePolicyLink}}</a> | 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" target="_blank" href="{{privacyPolicyHref}}">{{privacyPolicyLink}}</a> |
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" target="_blank" href="{{tosHref}}">{{tosLink}}</a>
    </span>
    `,
  },
  content: {
    message: 'By using our site, you acknowledge that you have read and understand our ',

    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: '',

    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: '',

    tosLink: 'Terms of Service',
    tosHref: '',
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    BlogHomeComponent,
    BlogSingleComponent,
    ContactComponent,
    GalleryComponent,
    ElementsComponent,
    MenuComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    FormatTimePipe,
    FormatTimeDaysPipe,
    FormatTimeHoursPipe,
    FormatTimeMinutesPipe,
    FormatTimeSecondsPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    BrMaskerModule,
    Ng2TelInputModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    MatProgressSpinnerModule,
    ParticlesModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
  ]
})
export class AppModule { }
