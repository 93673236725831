import { Injectable } from '@angular/core';
import { Util } from 'src/app/Util';
import { HttpClient } from '@angular/common/http';
import { Paths } from 'src/app/Paths';

export interface INewsletter {
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(
    private http: HttpClient
  ) { }

  public register(email) {
    // return this.http.post(`${Paths.NEWSLETTER}`,  Util.getSafeContent({ email }))
    return this.http.post(`${Paths.NEWSLETTER}`,  { email })
    .toPromise()
    .then((data) => Util.safeResponse(data));
  }

}
