import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/site/home/home.component';
import { AboutComponent } from './components/site/about/about.component';
import { BlogHomeComponent } from './components/site/blog-home/blog-home.component';
import { BlogSingleComponent } from './components/site/blog-single/blog-single.component';
import { ContactComponent } from './components/site/contact/contact.component';
import { ElementsComponent } from './components/site/elements/elements.component';
import { GalleryComponent } from './components/site/gallery/gallery.component';
import { MenuComponent } from './components/site/menu/menu.component';
import { CookiePolicyComponent } from './components/site/cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './components/site/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './components/site/terms-of-service/terms-of-service.component';


const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'home', component: HomeComponent},
  { path: 'about', component: AboutComponent},
  { path: 'blog-home', component: BlogHomeComponent},
  { path: 'blog-single', component: BlogSingleComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'elements', component: ElementsComponent},
  { path: 'gallery', component: GalleryComponent},
  { path: 'menu', component: MenuComponent},
  { path: 'cookie-policy', component: CookiePolicyComponent},
  { path: 'privacy-policy', component: PrivacyPolicyComponent},
  { path: 'terms-of-service', component: TermsOfServiceComponent},
  { path: 'terms-of-use', component: TermsOfServiceComponent},
  { path: '**', component: HomeComponent},
  { path: '404', component: HomeComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
