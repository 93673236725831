import * as CryptoJS from 'crypto-js';

export class Util {
    public static validateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    }

    public static readonly APPLICATION_ID: string = 'P8RS2#KDKgRYgLHp@KPs';

    public static getSafeContent(data) {
        const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), Util.APPLICATION_ID).toString();

        return {
            cipherText,
        };
    }

    public static safeResponse(body: any) {
        /*
        * write your logic to modify the body
        * */

        if (body.cipherText) {

            const bytes = CryptoJS.AES.decrypt(body.cipherText, Util.APPLICATION_ID);
            const bodyrealBody = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            return bodyrealBody;
        } else {
            return body;
        }

    }
}
