import { environment } from '../environments/environment';

export class Paths {
    public static readonly BASE_PATH = environment.basePath;
    // public static readonly BASE_PATH = 'https://e7651416.ngrok.io/delivery/v1/';

    public static readonly SOCKET_IO = Paths.BASE_PATH.replace('delivery/v1/', '');

    public static readonly INTERESTS = Paths.BASE_PATH + 'interests';

    public static readonly NEWSLETTER = Paths.BASE_PATH + 'newsletter';

    public static readonly ANALYTICS = Paths.BASE_PATH + 'analytics';
}
